// Import the image
// import image from "./image.png";

type Images = Record<string, string>;

// add the image to images object
// const images: Images = { image };

const images: Images = {};

export default images;
