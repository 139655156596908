import releaseNotes from "./release-notes";
import type { ReleaseNote } from "./release-notes";
import images from "../assets";

const getLatestReleaseNote = (language: string): ReleaseNote | undefined => {
  return releaseNotes.find((release) => release.language === language);
};

const getAllReleaseNotes = (language: string): ReleaseNote[] => {
  return releaseNotes.filter((release) => release.language === language);
};

const getInnerHtml = (html: string): string => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const imgElements = div.getElementsByTagName("img");

  for (const img in imgElements) {
    if (Object.prototype.hasOwnProperty.call(imgElements, img)) {
      const el = imgElements[img];
      const src = el.getAttribute("src") ?? "";
      el.setAttribute("src", images[src]);
    }
  }

  return div.innerHTML;
};

export { getAllReleaseNotes, getLatestReleaseNote, getInnerHtml };
